import React from 'react';
import "./index.scss";

import { animateBox } from "../../modules/componentAnimation";
import * as backendModule from "../../modules/backendModule";

import { FilteredCustomTable } from "../../components/customComponents/Table";
import Button from '../../components/customComponents/Button';
import Spinner from '../../components/customComponents/Spinner';

import axios from 'axios';
import moment from 'moment';

export default function Users() {
    const [errorMessage, setErrorMessage] = React.useState(null);
    const [filters, setFilters] = React.useState([]);
    const [users, setUsers] = React.useState([]);

    const getAllUsers = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/getAllUsers`,
            data: {
                filters: filters
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === 'ok') {
                setUsers(res.data);
            }
            else {
                setErrorMessage('Došlo je do greške...');
            }
        }).catch(() => {
            setErrorMessage('Problem sa serverom...');
        });
    }

    const addUsersToTable = () => {
        if (users.data) {
            return users?.data?.map((item) => {
                return [
                    { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.ID}</p></div> },
                    { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.Username}</p></div> },
                    { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.Email}</p></div> },
                    { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.PatientType}</p></div> },
                    { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{JSON.parse(item.Flags)?.isAdmin ? 'DA' : 'NE'}</p></div> },
                    { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.AccessExpires ? moment(item.AccessExpires).format('DD.MM.YYYY.') : 'Nikad'}</p></div> },
                    { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{moment(item.updatedAt).format('DD.MM.YYYY.')}</p></div> },
                    {
                        keyID: String(item.ID), type: "groupNewline", group: [
                            {
                                keyID: String(item.ID), type: "button", text: "Prikaži plan", triggerDropdown: true, onClick: e => {
                                    animateBox(e, <PreviewModal ID={item.PlanID} />)
                                }
                            },
                            {
                                keyID: String(item.ID), type: "button", text: "Obriši", triggerDropdown: true, triggerData: e => {
                                    return (<div style={{ display: 'flex', flexDirection: 'column', justfyContent: 'center', alignItems: 'center' }}>
                                        <p style={{ color: 'rgb(95, 132, 83)' }}>Da li ste sigurni?</p>
                                        <br></br>
                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <Button style={{ marginRight: '10px' }} accent='rgb(95, 132, 83)' value='YES' onClick={() => deleteUser(item.ID)} />
                                            <Button accent='rgb(95, 132, 83)' value='NO' onClick={c => { e() }} />
                                        </div>
                                    </div>);
                                },
                            },
                            {
                                keyID: String(item.ID), type: "button", text: "Uredi", triggerDropdown: true, onClick: e => {
                                    animateBox(e, <EditUserModal
                                        curUser={
                                            {
                                                ID: item.ID,
                                                Username: item.Username,
                                                Email: item.Email,
                                                PatientType: item.PatientType,
                                                AccessExpires: item.AccessExpires,
                                                PlanID: item.PlanID,
                                            }
                                        }
                                        getAll={getAllUsers}
                                    />)
                                }
                            },
                        ]
                    }
                ];
            });
        }
    }

    const deleteUser = (id) => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/deleteUser`,
            data: {
                id: id
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === 'ok') {
                getAllUsers();
            }
        }).catch(() => {

        });
    }

    React.useEffect(() => {
        getAllUsers();
    }, [filters]);

    return (
        <div className='route__users'>
            <div className='route__users__header'>
                <div className='route__users__header__button' onClick={(e) => animateBox(e, <ChangePasswordModal />)}>
                    <img src='images/changepassword.png' alt='' />
                    <p>Promijeni svoju šifru</p>
                </div>
                <div className='route__users__header__button' onClick={(e) => animateBox(e, <AddNewAdminUserModal getAll={getAllUsers} />)}>
                    <img src='images/plus.svg' alt='' />
                    <p>Dodaj admina</p>
                </div>
                <div className='route__users__header__button' onClick={(e) => animateBox(e, <AddNewUserModal getAll={getAllUsers} />)}>
                    <img src='images/plus.svg' alt='' />
                    <p>Dodaj novog korisnika</p>
                </div>
            </div>
            {!errorMessage && <div className='route__meals__body'>
                <FilteredCustomTable
                    id='table'
                    accent='#5f8453'
                    theme='light'
                    headers={['ID', 'Korisničko ime', 'Email', 'Tip', 'Admin', 'Ističe', 'Uređeno']}
                    filters={[
                        { name: "ID", friendlyName: "ID", type: "string" },
                        { name: "PatientType", friendlyName: "Tip pacijenta", type: "string" },
                        { name: "Username", friendlyName: "Korisničko ime", type: "string" },
                    ]}
                    filterCB={f => setFilters(f)}
                    data={users.data?.length > 0 ? addUsersToTable() : [[{ keyID: 'noData', type: 'custom', data: <p>No data to display</p> }]]}
                />
            </div>}
            {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
        </div>
    )
}

const AddNewAdminUserModal = (props) => {
    const usernameRef = React.useRef(null);
    const emailRef = React.useRef(null);
    const passwordRef = React.useRef(null);
    const [spinner, setSpinner] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState(null);

    const addUser = (close) => {
        if (
            !usernameRef.current.value ||
            !passwordRef.current.value ||
            !emailRef.current.value
        ) {
            return setErrorMessage('Sva polja moraju biti popunjena!');
        }

        if (usernameRef.current.value.length < 8) {
            return setErrorMessage('Korisničko ime mora sadržavati najmanje 8 karaktera!');
        }
        else {
            setSpinner(true);
        }

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/createNewUser`,
            data: {
                username: usernameRef.current.value,
                password: passwordRef.current.value,
                email: emailRef.current.value,
                flags: {
                    isAdmin: true
                }
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === 'ok') {
                props.getAll();
                close();
            }
        }).catch(() => {

        }).finally(() => setSpinner(false));
    }

    return <div className='component__addNewUserModal'>
        <div className='component__addNewUserModal__wrapper'>
            <img className='component__addNewUserModal__wrapper__close' src='images/close.svg' alt='' onClick={props.onClose} />
            <input type='text' placeholder='Korisničko ime' ref={usernameRef} />
            <input type='text' placeholder='email' ref={emailRef} />
            <input type='password' placeholder='Šifra' ref={passwordRef} />
            {!spinner && <Button style={{ fontWeight: '700' }} className='component__addNewUserModal__wrapper__button' value='Dodaj' accent='rgb(95, 132, 83)' onClick={() => addUser(props.onClose)} />}
            {spinner && <Spinner accent='rgb(95, 132, 83)' />}
            {errorMessage && <p>{errorMessage}</p>}
        </div>
    </div>
}

const AddNewUserModal = (props) => {
    const usernameRef = React.useRef(null);
    const emailRef = React.useRef(null);
    const passwordRef = React.useRef(null);
    const typeRef = React.useRef(null);
    const accessExpiresRef = React.useRef(null);
    const [planID, setPlanID] = React.useState(null);
    const [spinner, setSpinner] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState(null);

    const addUser = (close) => {
        if (
            !usernameRef.current.value ||
            !typeRef.current.value ||
            !passwordRef.current.value ||
            !emailRef.current.value ||
            !planID ||
            !accessExpiresRef.current.value
        ) {
            return setErrorMessage('Sva polja moraju biti popunjena!');
        }

        if (usernameRef.current.value.length < 8) {
            return setErrorMessage('Korisničko ime mora sadržavati najmanje 8 karaktera!');
        }
        else {
            setSpinner(true);
        }

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/createNewUser`,
            data: {
                username: usernameRef.current.value,
                patientType: typeRef.current.value,
                password: passwordRef.current.value,
                email: emailRef.current.value,
                planID: planID,
                accessExpires: accessExpiresRef.current.value,
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === 'ok') {
                props.getAll();
                close();
            }
        }).catch(() => {

        }).finally(() => setSpinner(false));
    }

    return <div className='component__addNewUserModal'>
        <div className='component__addNewUserModal__wrapper'>
            <img className='component__addNewUserModal__wrapper__close' src='images/close.svg' alt='' onClick={props.onClose} />
            <input type='text' placeholder='Korisničko ime' ref={usernameRef} />
            <input type='text' placeholder='email' ref={emailRef} />
            <input type='text' placeholder='Tip pacijenta' ref={typeRef} />
            <input type='password' placeholder='Šifra' ref={passwordRef} />
            <input type='date' ref={accessExpiresRef} />
            <Button className='component__addNewUserModal__wrapper__button' value={planID ? `${String(planID).substring(0, 30)}...` : 'Odaberi mjesečni plan'} accent='rgb(95, 132, 83)' onClick={(e) => animateBox(e, <PlansModal set={setPlanID} />)} />
            {!spinner && <Button style={{ fontWeight: '700' }} className='component__addNewUserModal__wrapper__button' value='Dodaj' accent='rgb(95, 132, 83)' onClick={() => addUser(props.onClose)} />}
            {spinner && <Spinner accent='rgb(95, 132, 83)' />}
            {errorMessage && <p>{errorMessage}</p>}
        </div>
    </div>
}

const EditUserModal = (props) => {
    const emailRef = React.useRef(null);
    const passwordRef = React.useRef(null);
    const typeRef = React.useRef(null);
    const accessExpiresRef = React.useRef(null);
    const [planID, setPlanID] = React.useState(props.curUser?.PlanID);
    const [spinner, setSpinner] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState(null);

    const saveUser = (close) => {
        if (
            !typeRef.current.value ||
            !emailRef.current.value ||
            !planID ||
            !accessExpiresRef.current.value
        ) {
            return setErrorMessage('Sva polja moraju biti popunjena!');
        }
        else {
            setSpinner(true);
        }

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/editUser`,
            data: {
                id: props.curUser?.ID,
                newPatientType: typeRef.current.value,
                newPassword: passwordRef.current.value ? passwordRef.current.value : '',
                newEmail: emailRef.current.value,
                newPlanID: planID,
                newAccessExpires: accessExpiresRef.current.value,
                newFlags: []
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === 'ok') {
                props.getAll();
                close();
            }
        }).catch(() => {

        }).finally(() => setSpinner(false));
    }

    return <div className='component__addNewUserModal'>
        <div className='component__addNewUserModal__wrapper'>
            <img className='component__addNewUserModal__wrapper__close' src='images/close.svg' alt='' onClick={props.onClose} />
            <input type='text' placeholder='email' ref={emailRef} defaultValue={props.curUser?.Email} />
            <input type='text' placeholder='Tip pacijenta' ref={typeRef} defaultValue={props.curUser?.PatientType} />
            <input type='password' placeholder='Šifra' ref={passwordRef} />
            <input type='date' ref={accessExpiresRef} defaultValue={moment(props.curUser?.AccessExpires).format('YYYY-MM-DD')} />
            <Button className='component__addNewUserModal__wrapper__button' value={planID ? `${String(planID).substring(0, 10)}...` : 'Odaberi mjesečni plan'} accent='rgb(95, 132, 83)' onClick={(e) => animateBox(e, <PlansModal set={setPlanID} />)} />
            {!spinner && <Button style={{ fontWeight: '700' }} className='component__addNewUserModal__wrapper__button' value='Sačuvaj' accent='rgb(95, 132, 83)' onClick={() => saveUser(props.onClose)} />}
            {spinner && <Spinner accent='rgb(95, 132, 83)' />}
            {errorMessage && <p>{errorMessage}</p>}
        </div>
    </div>
}

const PlansModal = (props) => {
    const [errorMessage, setErrorMessage] = React.useState(null);
    const [filters, setFilters] = React.useState([]);
    const [plans, setPlans] = React.useState([]);

    const getAllPlans = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/plans/getAllPlans`,
            data: {
                filters: filters
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === 'ok') {
                setPlans(res.data);
            }
            else {
                setErrorMessage('Došlo je do greške...');
            }
        }).catch(() => {
            setErrorMessage('Problem sa serverom...');
        });
    }

    const addPlansToTable = () => {
        if (plans.data) {
            return plans?.data?.map((item) => {
                return [
                    { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.ID}</p></div> },
                    { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.Name}</p></div> },
                    { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.Type}</p></div> },
                    { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{moment(item.updatedAt).format('DD.MM.YYYY.')}</p></div> },
                    {
                        keyID: String(item.ID), type: "groupNewline", group: [
                            {
                                keyID: String(item.ID), type: "button", text: "Odaberi", triggerDropdown: true, triggerData: e => {
                                    props.set(item.ID);
                                    props.onClose();
                                },
                            },
                        ]
                    }
                ];
            });
        }
    }

    React.useEffect(() => {
        getAllPlans();
    }, []);

    return <div className='component__plansModal'>
        {!errorMessage && <div className='component__plansModal__wrapper'>
            <img className='component__plansModal__wrapper__close' src='images/close.svg' alt='' onClick={props.onClose} />
            <FilteredCustomTable
                id='table'
                accent='#5f8453'
                theme='light'
                headers={['ID', 'Naziv', 'Tip', 'Uređeno']}
                filters={[
                    { name: "ID", friendlyName: "ID", type: "number" },
                    { name: "Name", friendlyName: "Naziv", type: "string" },
                    { name: "Type", friendlyName: "Tip", type: "string" },
                ]}
                filterCB={f => setFilters(f)}
                data={plans.data?.length > 0 ? addPlansToTable() : [[{ keyID: 'noData', type: 'custom', data: <p>No data to display</p> }]]}
            />
        </div>}
        {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
    </div>
}

const PreviewModal = (props) => {
    const [finalDays, setFinalDays] = React.useState([]);
    const [spinner, setSpinner] = React.useState(false);

    React.useEffect(() => {
        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/plans/getAllPlans`,
            data: {
                filters: [
                    { name: 'ID', op: 'eq', value: props.ID }
                ]
            },
            ...backendModule.axiosConfig,
        }).then(res => {
            if (res.data.status === 'ok') {
                setFinalDays(JSON.parse(res.data.data[0].Days));
            }
        }).catch(() => {

        }).finally(() => {

        });
    }, []);

    React.useEffect(() => {
        if (finalDays.length > 0) {
            finalDays?.map((item, index) => {
                axios({
                    method: "POST",
                    url: `${backendModule.backendURL}/day/getOneDay`,
                    data: {
                        PreparedMealID: item.Meal,
                        SportID: item.Sport,
                        MeditationID: item.Meditation
                    },
                    ...backendModule.axiosConfig,
                }).then(res => {
                    if (res.data.status === 'ok') {
                        finalDays[index].Meal = { Breakfast: res.data.data.Breakfast, Lunch: res.data.data.Lunch, Dinner: res.data.data.Dinner };
                        finalDays[index].Sport = res.data.data.Sports;
                        finalDays[index].Meditation = res.data.data.Meditations;
                    }
                    else {

                    }
                }).catch(() => {

                }).finally(() => {
                    if (index === finalDays.length - 1) {
                        setTimeout(() => {
                            setSpinner(false);
                        }, 100)
                    }
                });
            });
        }
    }, [finalDays]);

    return <div className='component__previewModal'>
        <div className='component__previewModal__wrapper'>
            <img src='images/close.svg' alt='' onClick={props.onClose} />
            {
                !spinner ?
                    finalDays?.map(item => {
                        return <div>
                            <p><strong>Dan:</strong> {item.Day}</p>
                            <p><strong>Obroci:</strong> {item.Meal?.Breakfast}, {item.Meal?.Lunch}, {item.Meal?.Dinner}</p>
                            <p><strong>Sport: </strong>
                                {
                                    item.Sport?.map(item => {
                                        return ` ${item}, `;
                                    })
                                }
                            </p>
                            <p><strong>Meditacija: </strong>
                                {
                                    item.Meditation?.map(item => {
                                        return ` ${item}, `;
                                    })
                                }
                            </p>
                        </div>
                    })
                    : <Spinner color='#5f8453' />
            }
        </div>
    </div>
}

const ChangePasswordModal = (props) => {
    const [spinner, setSpinner] = React.useState(false);
    const [message, setMessage] = React.useState(null);

    const oldPasswordRef = React.useRef(null);
    const newPasswordRef = React.useRef(null);

    const savePassword = (close) => {
        if (!oldPasswordRef.current.value || !newPasswordRef.current.value) {
            return setMessage('Sva polja moraju biti popunjena!');
        }

        setSpinner(true);

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/changePassword`,
            data: {
                oldPassword: oldPasswordRef.current.value,
                newPassword: newPasswordRef.current.value
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                close();
            }
            else {
                setMessage('Stara šifra nije tačna!');
            }
        }).catch(() => {
            setMessage('Server ne reagira...');
        }).finally(() => {
            setSpinner(false);
        });
    }

    return <div className='componanet__changePasswordModal'>
        <img src='images/close.svg' alt='' onClick={props.onClose} />
        <input ref={oldPasswordRef} type='password' placeholder='Stara šifra' alt='' />
        <input ref={newPasswordRef} type='password' placeholder='Nova šifra' alt='' />
        {!spinner && <Button accent='#5f8453' value='Sačuvaj' onClick={() => savePassword(props.onClose)} />}
        {spinner && <Spinner align='center' color='#5f8453' />}
        {message && <p style={{ color: 'red' }}>{message}</p>}
    </div>
}