import React from 'react';
import "./index.scss";

import { animateBox } from "../../modules/componentAnimation";
import * as backendModule from "../../modules/backendModule";

import { FilteredCustomTable } from "../../components/customComponents/Table";
import Button from '../../components/customComponents/Button';
import Spinner from '../../components/customComponents/Spinner';

import axios from 'axios';
import moment from 'moment';

export default function ApiTokens(props) {
    const [errorMessage, setErrorMessage] = React.useState(null);
    const [filters, setFilters] = React.useState([]);
    const [tokens, setTokens] = React.useState([]);
    const [flags, setFlags] = React.useState([]);

    const getAllFlags = () => {
        axios({
            method: 'POST',
            url: `${backendModule.backendURL}/apiTokens/getAllAvailableFlags`,
            data: {
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === 'ok') {
                setFlags(res.data);
            };
        }).catch(() => {
            setErrorMessage('Server ne reagira...');
        });
    }

    const getAllApiTokens = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/apiTokens/getAllApiTokens`,
            data: {
                filters: filters
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === 'ok') {
                setTokens(res.data);
            }
            else {
                setErrorMessage('Došlo je do greške...');
            }
        }).catch(() => {
            setErrorMessage('Problem sa serverom...');
        });
    }

    const addApiTokensToTable = () => {
        if (tokens.data) {
            return tokens?.data?.map((item) => {
                return [
                    { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.ID}</p></div> },
                    { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.Name}</p></div> },
                    { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.UserID}</p></div> },
                    { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{moment(item.updatedAt).format('DD.MM.YYYY.')}</p></div> },
                    {
                        keyID: String(item.ID), type: "groupNewline", group: [
                            {
                                keyID: String(item.ID), type: "custom", data: <div>{
                                    (() => {
                                        let activeFlags = [];
                                        for (const flag in item.Flags) {
                                            if (item.Flags[flag] === true) {
                                                flags.data?.map((f) => {
                                                    if (f.name === flag) {
                                                        activeFlags.push(f.friendlyName);
                                                    }
                                                });
                                            }
                                        }
                                        return <div className='table__flags'>{activeFlags?.map((f) => { return <p>{f}</p> })}</div>
                                    })()
                                }</div>
                            },
                        ]
                    },
                    {
                        keyID: String(item.ID), type: "groupNewline", group: [
                            {
                                keyID: String(item.ID), type: "button", text: "Prikaži token", triggerDropdown: true, triggerData: e => <GetToken id={item.ID} />
                            },
                            {
                                keyID: String(item.ID), type: "button", text: "Obriši", triggerDropdown: true, triggerData: e => {
                                    return (<div style={{ display: 'flex', flexDirection: 'column', justfyContent: 'center', alignItems: 'center' }}>
                                        <p style={{ color: 'rgb(95, 132, 83)' }}>Da li ste sigurni?</p>
                                        <br></br>
                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <Button style={{ marginRight: '10px' }} accent='rgb(95, 132, 83)' value='YES' onClick={() => deleteToken(item.ID)} />
                                            <Button accent='rgb(95, 132, 83)' value='NO' onClick={c => { e() }} />
                                        </div>
                                    </div>);
                                },
                            },
                        ]
                    }
                ];
            });
        }
    }

    const deleteToken = (id) => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/apiTokens/removeApiToken`,
            data: {
                ID: id
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === 'ok') {
                getAllApiTokens();
            }
        }).catch(() => {

        });
    }

    React.useEffect(() => {
        getAllApiTokens();
    }, [filters]);

    React.useEffect(() => {
        getAllFlags();
    }, [])

    return (
        <div className='route__tokens'>
            <div className='route__tokens__header'>
                <div className='route__tokens__header__button' onClick={(e) => animateBox(e, <AddNewTokenModal userID={props.userInfo?.ID} getAll={getAllApiTokens} />)}>
                    <img src='images/plus.svg' alt='' />
                    <p>Kreiraj novi api token</p>
                </div>
            </div>
            {!errorMessage && <div className='route__meals__body'>
                <FilteredCustomTable
                    id='table'
                    accent='#5f8453'
                    theme='light'
                    headers={['ID', 'Name', 'UserID', 'Uređeno']}
                    filters={[
                        { name: "ID", friendlyName: "ID", type: "number" },
                        { name: "Name", friendlyName: "Naziv tokena", type: "string" },
                    ]}
                    filterCB={f => setFilters(f)}
                    data={tokens.data?.length > 0 ? addApiTokensToTable() : [[{ keyID: 'noData', type: 'custom', data: <p>No data to display</p> }]]}
                />
            </div>}
            {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
        </div>
    )
}

const AddNewTokenModal = (props) => {
    const nameRef = React.useRef(null);
    const [spinner, setSpinner] = React.useState(true);
    const [errorMessage, setErrorMessage] = React.useState(null);
    const [selectedFlags, setSelectedFlags] = React.useState([]);

    const [flags, setFlags] = React.useState([]);
    const getAllFlags = () => {
        axios({
            method: 'POST',
            url: `${backendModule.backendURL}/apiTokens/getAllAvailableFlags`,
            data: {
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === 'ok') {
                setFlags(res.data);
            };
        }).catch(() => {
            setErrorMessage('Server ne reagira...');
        }).finally(() => {
            setSpinner(false);
        });
    }

    const addToken = (close) => {
        if (
            !nameRef.current.value
        ) {
            return setErrorMessage('Sva polja moraju biti popunjena!');
        }
        else {
            setSpinner(true);
        }

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/apiTokens/addApiToken`,
            data: {
                Name: nameRef.current.value,
                Flags: selectedFlags,
                UserID: props.userID
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === 'ok') {
                props.getAll();
                close();
            }
        }).catch(() => {

        }).finally(() => setSpinner(false));
    }

    React.useEffect(() => {
        getAllFlags();
    }, []);

    return <div className='component__addNewTokenModal'>
        <div className='component__addNewTokenModal__wrapper'>
            <img className='component__addNewTokenModal__wrapper__close' src='images/close.svg' alt='' onClick={props.onClose} />
            <input type='text' placeholder='Naziv tokena' ref={nameRef} />
            {!spinner && <div className='component__addNewTokenModal__wrapper__flags'>
                {
                    flags.data?.map((item, index) => {
                        return <div className='component__addNewTokenModal__wrapper__flags__singleFlag'>
                            <input type='checkbox'
                                onChange={(e) => {
                                    if (e.currentTarget.checked) {
                                        setSelectedFlags(prev => {
                                            return {
                                                ...prev,
                                                [item.name]: true
                                            }
                                        })
                                    }
                                    else {
                                        setSelectedFlags(prev => {
                                            return {
                                                ...prev,
                                                [item.name]: false
                                            }
                                        })
                                    }
                                }}
                            />
                            <p className='component__addNewTokenModal__wrapper__flags__singleFlag__name'>{item.friendlyName}</p>
                            <p className='component__addNewTokenModal__wrapper__flags__singleFlag__description'>{item.description}</p>
                        </div>
                    })
                }
            </div>}
            {!spinner && <Button style={{ fontWeight: '700' }} className='component__addNewUserModal__wrapper__button' value='Kreiraj' accent='rgb(95, 132, 83)' onClick={() => addToken(props.onClose)} />}
            {spinner && <Spinner accent='#5f8453' />}
            {errorMessage && <p>{errorMessage}</p>}
        </div>
    </div>
}

const GetToken = (props) => {
    const [token, setToken] = React.useState('');

    const getCurToken = (id) => {
        axios({
            method: 'POST',
            url: `${backendModule.backendURL}/apiTokens/getToken`,
            data: {
                ID: id
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === 'ok') {
                setToken(res.data);
            };
        }).catch(() => {

        });
    }

    React.useEffect(() => {
        getCurToken(props.id);
    }, []);

    return (<div style={{ display: 'flex', flexDirection: 'column', justfyContent: 'center', alignItems: 'center' }}>
        <br></br>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
            <p id='token'>{token?.data ?? ''}</p>
        </div>
    </div>);
}