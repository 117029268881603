import React from 'react';
import "./index.scss";

import { useNavigate } from "react-router-dom";
import animateModule from "../../modules/animateModule";

import Meals from "../Meals";
import PreparedMeals from '../PreparedMeals';
import Workouts from '../Workouts';
import MonthlyPlans from '../MonthlyPlans';
import Users from '../Users';
import ApiTokens from '../ApiTokens';
import Messages from '../Messages';

export default function AdminPanel(props) {
  const curNavigate = useNavigate();
  const animateNavigate = to => animateModule(curNavigate, to, document.querySelector(".route__adminPanel__body"));

  return (
    <div className='route__adminPanel'>
      <div className='route__adminPanel__sidebar'>
        <div style={{ border: window.location.hash === '#/adminpanel' ? '1px solid white' : '' }} onClick={() => animateNavigate('/adminpanel')}>
          <img src='images/meals.svg' alt='' />
          <p>Obroci</p>
        </div>
        <div style={{ border: window.location.hash === '#/adminpanel/prepared-meals' ? '1px solid white' : '' }} onClick={() => animateNavigate('/adminpanel/prepared-meals')}>
          <img src='images/preparedMeals.svg' alt='' />
          <p>Dnevni planovi</p>
        </div>
        <div style={{ border: window.location.hash === '#/adminpanel/workouts' ? '1px solid white' : '' }} onClick={() => animateNavigate('/adminpanel/workouts')}>
          <img src='images/workouts.svg' alt='' />
          <p>Vježbe</p>
        </div>
        <div style={{ border: window.location.hash === '#/adminpanel/monthly-plans' ? '1px solid white' : '' }} onClick={() => animateNavigate('/adminpanel/monthly-plans')}>
          <img src='images/plans.svg' alt='' />
          <p>Mjesečni planovi</p>
        </div>
        <div style={{ border: window.location.hash === '#/adminpanel/users' ? '1px solid white' : '' }} onClick={() => animateNavigate('/adminpanel/users')}>
          <img src='images/users.svg' alt='' />
          <p>Korisnici</p>
        </div>
        <div style={{ border: window.location.hash === '#/adminpanel/api' ? '1px solid white' : '' }} onClick={() => animateNavigate('/adminpanel/api')}>
          <img src='images/tokens.png' alt='' />
          <p>API tokeni</p>
        </div>
        <div style={{ border: window.location.hash === '#/adminpanel/messages' ? '1px solid white' : '' }} onClick={() => animateNavigate('/adminpanel/messages')}>
          <img src='images/messagesIcon.svg' alt='' />
          <p>Poruke</p>
        </div>
      </div>
      <div className='route__adminPanel__body'>
        {
          window.location.hash === '#/adminpanel' && <Meals />
        }
        {
          window.location.hash === '#/adminpanel/prepared-meals' && <PreparedMeals />
        }
        {
          window.location.hash === '#/adminpanel/workouts' && <Workouts />
        }
        {
          window.location.hash === '#/adminpanel/monthly-plans' && <MonthlyPlans />
        }
        {
          window.location.hash === '#/adminpanel/users' && <Users />
        }
        {
          window.location.hash === '#/adminpanel/api' && <ApiTokens userInfo={props.userInfo} />
        }
        {
          window.location.hash === '#/adminpanel/messages' && <Messages />
        }
      </div>
    </div>
  )
}
