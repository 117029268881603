import React from 'react';
import "./index.scss";

import { animateBox } from "../../modules/componentAnimation";
import * as backendModule from "../../modules/backendModule";

import { FilteredCustomTable } from "../../components/customComponents/Table";
import Button from '../../components/customComponents/Button';

import axios from 'axios';
import moment from 'moment';

export default function Messages() {
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [filters, setFilters] = React.useState([]);
  const [messages, setMessages] = React.useState([]);

  const getAllMessages = () => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/messages/getAllMessages`,
      data: {
        filters: filters
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === 'ok') {
        setMessages(res.data);
      }
      else {
        setErrorMessage('Došlo je do greške...');
      }
    }).catch(() => {
      setErrorMessage('Problem sa serverom...');
    });
  }

  const addMessagesToTable = () => {
    if (messages.data) {
      return messages?.data?.map((item) => {
        return [
          { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.ID}</p></div> },
          { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.FromUserID}</p></div> },
          { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{item.Title}</p></div> },
          { keyID: String(item.ID), type: "custom", data: <div><p style={{ textAlign: 'center' }}>{moment(item.updatedAt).format('DD.MM.YYYY.')}</p></div> },
          {
            keyID: String(item.ID), type: "groupNewline", group: [
              {
                keyID: String(item.ID), type: "button", text: "Pročitaj poruku", triggerDropdown: true, onClick: e => {
                  animateBox(e, <ViewMessageModal message={item.Message} />);
                }
              },
              {
                keyID: String(item.ID), type: "button", text: "Obriši", triggerDropdown: true, triggerData: e => {
                  return (<div style={{ display: 'flex', flexDirection: 'column', justfyContent: 'center', alignItems: 'center' }}>
                    <p style={{ color: 'rgb(95, 132, 83)' }}>Da li ste sigurni?</p>
                    <br></br>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                      <Button style={{ marginRight: '10px' }} accent='rgb(95, 132, 83)' value='DA' onClick={() => deleteMessage(item.ID)} />
                      <Button accent='rgb(95, 132, 83)' value='NE' onClick={c => { e() }} />
                    </div>
                  </div>);
                },
              },
            ]
          },
        ];
      });
    }
  }

  const deleteMessage = (id) => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/messages/removeMessage`,
      data: {
        ID: id
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === 'ok') {
        getAllMessages();
      }
    }).catch(() => {

    });
  }

  React.useEffect(() => {
    getAllMessages();
  }, [filters]);

  return (
    <div className='route__messages'>
      {!errorMessage && <div className='route__messages__body'>
        <FilteredCustomTable
          id='table'
          accent='#5f8453'
          theme='light'
          headers={['ID', 'ID korisnika', 'Naslov', 'Pristiglo']}
          filters={[
            { name: "ID", friendlyName: "ID", type: "string" },
            { name: "FromUserID", friendlyName: "ID korisnika", type: "string" },
          ]}
          filterCB={f => setFilters(f)}
          data={messages.data?.length > 0 ? addMessagesToTable() : [[{ keyID: 'noData', type: 'custom', data: <p>No data to display</p> }]]}
        />
      </div>}
      {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
    </div>
  )
}

const ViewMessageModal = (props) => {
  return <div className='component__viewMessageModal'>
      <div className='component__viewMessageModal__wrapper'>
          <img className='component__viewMessageModal__wrapper__close' src='images/close.svg' alt='' onClick={props.onClose} />
          <p>{props.message}</p>
      </div>
  </div>
}