import { HashRouter as Router, Routes, Route } from "react-router-dom";
import { useSelector } from "react-redux";

import Header from "./components/Header";
import ContentWrapper from "./components/ContentWrapper";

import LandingPage from "./routes/LandingPage";
import YourProgram from "./routes/YourProgram";
import AdminPanel from "./routes/AdminPanel";

import Spinner from "./components/customComponents/Spinner";

import * as backendModule from "../src/modules/backendModule";
import axios from "axios";
import React from "react";

const App = () => {
  const timestampSelector = useSelector(state => state.timestamp);
  const [logged, setLogged] = React.useState(null);
  const [loggedUser, setLoggedUser] = React.useState(null);

  const checkLogin = () => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/auth/checkLogin`,
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        setLogged(true);
        setLoggedUser(res.data.data.UserInfo);
      }
      else {
        setLogged(false);
      }
    }).catch(() => {
      setLogged(false);
    });
  };

  React.useEffect(() => {
    checkLogin();
  }, [timestampSelector]);

  if (logged === null) return <Spinner style={{ width: "64px", height: "64px" }} align='center' color='#517047' />

  return <Router>
    <Header logged={logged} userInfo={loggedUser} />
    <ContentWrapper>
      <Routes>
        <Route path="/" element={<LandingPage userInfo={loggedUser} isLogged={logged} />} />
        {logged && !loggedUser?.Flags?.isAdmin && <Route path="/vasprogram" element={<YourProgram userInfo={loggedUser} isLogged={logged} />} />}
        {logged && loggedUser?.Flags?.isAdmin && <Route path="/adminpanel" element={<AdminPanel/>} />}
        {logged && loggedUser?.Flags?.isAdmin && <Route path="/adminpanel/dishes" element={<AdminPanel />} />}
        {logged && loggedUser?.Flags?.isAdmin && <Route path="/adminpanel/meals" element={<AdminPanel />} />}
        {logged && loggedUser?.Flags?.isAdmin && <Route path="/adminpanel/prepared-meals" element={<AdminPanel />} />}
        {logged && loggedUser?.Flags?.isAdmin && <Route path="/adminpanel/workouts" element={<AdminPanel />} />}
        {logged && loggedUser?.Flags?.isAdmin && <Route path="/adminpanel/monthly-plans" element={<AdminPanel />} />}
        {logged && loggedUser?.Flags?.isAdmin && <Route path="/adminpanel/users" element={<AdminPanel />} />}
        {logged && loggedUser?.Flags?.isAdmin && <Route path="/adminpanel/api" element={<AdminPanel userInfo={loggedUser} />} />}
        {logged && loggedUser?.Flags?.isAdmin && <Route path="/adminpanel/messages" element={<AdminPanel />} />}
      </Routes>
    </ContentWrapper>
  </Router>
};

export default App;
